<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/mpwx/wxcontent' }">图文管理</el-breadcrumb-item>
                <el-breadcrumb-item>添加</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <el-row>
                <el-col :span="17">
                    <el-form size="medium" ref="form" :label-width="this.env.label_width">
                        <el-col :span="24">
                            <el-form-item label="标题">
                                <el-input v-model="form.title"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="封面">
                                <Qnupload v-model="form.cover" :sum="1"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="简介">
                                <el-input v-model="form.abstract" type="textarea"
                                          :autosize="{ minRows: 4, maxRows: 6} "
                                          maxlength="1000" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="类型">
                                <el-radio-group v-model="form.type">
                                    <el-radio :label="2">url</el-radio>
                                    <el-radio :label="1">富文本</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>

                        <el-col :span="24" v-show="form.type===1">
                            <el-form-item label="类型">
                                <Wangeditor ref="wangeditor" v-model="form.content" idindex="2"
                                            seewWidth="50%"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24" v-show="form.type===2">
                            <el-form-item label="图文链接">
                                <el-input v-model="form.url"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="24">
                            <el-form-item>
                                <el-button v-if="is_auth('wxmp.wxcontent.issave')"
                                           size="medium" type="primary" @click="save">保存
                                </el-button>
                                <el-button size="medium" @click="isreturn">返回</el-button>
                            </el-form-item>
                        </el-col>
                    </el-form>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import Qnupload from '@/components/Qnupload'
import Wangeditor from '@/components/Wangeditor'

export default {
    components: {
        Qnupload,
        Wangeditor,
    },
    data() {
        return {
            form: {
                title: '',
                cover: '',
                abstract: '',
                type: 2,
                content: '',
                url: '',
            },
        }
    },
    // 创建
    created() {
        this.init();
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            let wxcontent_uuid = this.$route.query.wxcontent_uuid
            if (wxcontent_uuid !== undefined) {
                this.getinfo(wxcontent_uuid)
            } else {
                this.loading = false
            }
        },
        // 获取详情
        getinfo(wxcontent_uuid = '') {
            let postdata = {
                api_name: "wxmp.wxcontent.getinfo",
                token: this.Tool.get_l_cache('token'),
                wxcontent_uuid
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.form = json.data
                    this.$refs.wangeditor.append(json.data.content);
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 保存
        save() {
            let postdata = {
                api_name: "wxmp.wxcontent.issave",
                token: this.Tool.get_l_cache('token'),
            }
            Object.assign(postdata, this.form);

            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.page = 1
                            this.form = {}
                            this.$router.push({path: '/mpwx/wxcontent'})
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 返回
        isreturn() {
            // this.$router.push({path: '/store/store'})
            this.$router.go(-1)
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
